/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import { Swipeable } from 'react-swipeable'
import { useMediaSize, MeasureProvider, useMeasure } from '@bestyled/contrib-common'

import { I18nProvider } from '@bestyled/primitives'
import _ from 'lodash'

import { GlobalStyles } from '../styles/global'
import { mediaqueries } from '../styles/media'
import GridLayoutProvider from '../styles/GridLayoutProvider'
import { getBreakpointPxFromTheme, getWindowDimensions, scrollable, hexToRGBA } from '../utils'
import { useI18n } from '@bestyled/primitives'

// import {
//   GlobalStyles,
//   mediaqueries,
//   getBreakpointPxFromTheme,
//   getWindowDimensions,
//   scrollable,
//   GridLayoutProvider
// } from '../../../../slipway-theme'

import NavigationMenuMobile from './Navigation.Menu.Mobile'
import NavigationFooter from './Navigation.Footer'
import NavigationHeader from './Navigation.Header'
import { useAppContext } from '../utils/global-context'
import {BackToTop} from './BackToTop'


import 'react-web-animations-js-wrapper'
import '../styles/simple-grid.scss'

// fix dependencies
require.resolve('web-animations-js')

interface LayoutProps {
  location: any
  basePath: string
  locale: string
  languages: any
  translations: any
  padded?: boolean
  footer?: {
    visible?: boolean
  }
  [x: string]: any
}

/**
 * <Layout /> needs to wrap every page as it provides styles, navigation,
 * and the main structure of each page. Within Layout we have the <Container />
 * which hides a lot of the mess we need to create our Desktop and Mobile experiences.
 */
const Layout: React.FC<LayoutProps> = ({
  basePath,
  location,
  locale,
  languages,
  translations,
  children,
  padded,
  themeOptions = {}
}) => {
  const [active, setActive] = useState(false)
  const [ctx = { theme: null }, setThemeCTX] = useAppContext()
  const theme: any = useTheme()
  const newTheme = {...theme, ...(themeOptions.themeOverrides ? themeOptions.themeOverrides : {}) }
  React.useEffect(() => {
    if (themeOptions.themeOverrides && _.difference(_.keys(newTheme), _.keys(theme)).length > 0) {
      setThemeCTX((prevTheme = { theme: {} }) => {
        if (themeOptions.themeOverrides && _.difference(_.keys(newTheme), _.keys(prevTheme.theme)).length > 0)
          return { theme: { ...theme, ...themeOptions.themeOverrides } }
        return theme
        // return { ...theme, ...themeOptions.themeOverrides }
      })
    }
  }, [])

  const breakpointsCombined = [
    ['phone_small', '320px'],
    ['phone', '376px'],
    ['phablet', '540px'],
    ['tablet', '735px'],
    ['tablet_large', '860px'],
    ['desktop', '1070px'],
    ['desktop_medium', '1280px'],
    ['desktop_large', '1440px']
  ]
  if (!theme.breakpoints) {
    theme.breakpointLabels = breakpointsCombined.map((bp) => bp[0])
    theme.breakpoints = breakpointsCombined.map((bp) => bp[1])
  }

  const { size: mediaSize } = useMediaSize()

  // TODO: FIX THIS
  // let mediaSize
  const isMobile = mediaSize - 1 < theme.breakpointLabels.tablet_large

  /**
   * Reset menu whenever we resize from mobile to non-mobile or vice versa
   * as the menu layout is quite different and this avoids left over artifacts
   */
  React.useEffect(() => {
    if (active) {
      setActive(false)
    }
  }, [isMobile])

  React.useEffect(() => {
    const ev = () => {
      window.localStorage.setItem('previousPath', '')
    }
    window.addEventListener('beforeunload', ev)
    return () => {
      window.removeEventListener('beforeunload', ev)
    }
  }, [])

  return isMobile ? (
    <I18nProvider basePath={basePath} locale={locale} languages={languages} translations={translations}>
      <MeasureProvider>
        <MobileContainer active={active} setActive={(value) => setActive(value)} location={location}>
          <ContentLayout location={location} active={active} setActive={(value) => setActive(value)} padded={padded}>
            {children}
          </ContentLayout>
        </MobileContainer>
      </MeasureProvider>
    </I18nProvider>
  ) : (
    <I18nProvider basePath={basePath} locale={locale} languages={languages} translations={translations}>
      <ContentLayout location={location} active={active} setActive={(value) => setActive(value)} padded={padded}>
        {children}
      </ContentLayout>
    </I18nProvider>
  )
}

export default Layout

const ContentLayout: React.FC<
  Partial<LayoutProps> & {
    active: boolean
    location: Location
    setActive: (boolean) => void
  }
> = ({ active, locale, setActive, children, padded, location: loc }) => {
  const { basePath, basePathI18n } = useI18n()

  const isHomepage = loc?.pathname === basePathI18n || loc?.pathname === basePath
  return (
    <ContentContainer>
      <GlobalStyles />
      <div id='element-anchor'></div>
      {/* @ts-ignore */}
      <NavigationHeader location={loc} active={active} setActive={setActive} isHomepage={isHomepage} id="top" />
      {children}
      <NavigationFooter />
      <BackToTop />
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  position: relative;
  background: ${(p) => p.theme.colors.background};
  transition: ${(p) => p.theme.colorModeTransition};
  min-height: 100vh;
  /* max-width: 50rem; */
  /* margin: 2rem auto; */
`

const MOBILE_MENU_DURATION = 500

const MobileContainer: React.FC<{
  active: boolean
  setActive: (boolean) => void
  location: any
}> = ({ active, setActive, location, children }) => {
  const theme = useTheme()

  const containerRef = React.useRef(null)

  const [mobileMenuOffset, setMobileMenuOffset] = useState(0)
  const [shouldMaskMobile, setShouldMaskMobile] = useState(false)

  const { height } = useMeasure()
  const mobileMenuActualHeight = height + 160 // + padding of outerNavigationMobileContainer
  const { basePath, basePathI18n } = useI18n()

  const isHomepage = location?.pathname === basePathI18n || location?.pathname === basePath


  /**
   * When we close the mobile nav we have to play a small trick
   * on the user to make it feel like the page transition is keeping state.
   * To do that, we put a Mask over the page content to make it
   * feel smoother and more enjoyable.
   */
  const closeMobileMenu = () => {
    setMobileMenuOffset(0)
    setActive(false)

    // Don't forget to enable scrolling once the nav is closed!
    setTimeout(() => {
      scrollable('enable')
    }, MOBILE_MENU_DURATION)
  }

  /**
   * Since the mobile menu vertically offests the entire page we want to
   * ensure the page is still useable
   */
  const openMobileMenu = () => {
    const { height } = getWindowDimensions()

    // Open the nav at the calculated offset, and then disable scrolling
    setMobileMenuOffset(mobileMenuActualHeight)
    setActive(true)
    scrollable('disable')
  }

  React.useEffect(() => {
    if (active) {
      openMobileMenu()
    } else {
      scrollable('enable')
    }
  }, [active])

  /**
   * we’re hijacking the <Link to="/path" /> functionality here. When a user
   * taps a link we prevent default, close the nav, apply the mask, and _then_
   * finally navigate to the new page.
   */
  const navigateMobileOut = (event, path) => {
    event.preventDefault()
    const { pathname } = window.location
    const isNavigatingToNewPage = !pathname.includes(path) || pathname.split('/')[1]

    // Nav closes
    closeMobileMenu()

    // If it's a newly selected page, apply the mask and then wait wait a few ms
    if (isNavigatingToNewPage) {
      setShouldMaskMobile(true)

      setTimeout(() => {
        navigate(path)
      }, MOBILE_MENU_DURATION)
    }
  }

  return (
    <>
      <NavigationMenuMobile
        theme={theme}
        isMobileMenuActive={active}
        navigateOut={navigateMobileOut}
        isHomepage={isHomepage}
      />
      <Swipeable onSwipedUp={closeMobileMenu}>
        <StyledInnnerContainer
          theme={theme}
          isMobileMenuActive={active}
          mobileMenuOffset={mobileMenuOffset}
          onClick={
            active
              ? closeMobileMenu
              : () => {
                  /** noop */
                }
          }
          ref={containerRef}
        >
          {/**
           * This Mask is only applied when navigation to a new page. It's how
           * we’re able to make it feel smooth between mobile navigations
           */}
          <MaskMobile shouldMask={shouldMaskMobile} />

          {/* The rest of the site lives in children */}
          {children}
        </StyledInnnerContainer>
      </Swipeable>
    </>
  )
}

interface SiteContainerProps {
  isMobileMenuActive: boolean
  mask?: boolean
  mobileMenuOffset: number
}

const StyledInnnerContainer = styled.div<SiteContainerProps>`
  position: ${(p) => (p.isMobileMenuActive ? 'fixed' : 'relative')};
  background: ${(p) => p.theme.colors.background};
  transition: ${(p) => p.theme.colorModeTransition};
  min-height: 100vh;
  padding-top: 0px;

  ${mediaqueries.tablet_large`
    padding-top: ${(p) => (p.padded ? '140px' : '0px')};
    // transform: ${(p) => (p.isMobileMenuActive ? `translateY(${p.mobileMenuOffset}px)` : 'none')};
    // transition: opacity ${MOBILE_MENU_DURATION + 60}ms,${MOBILE_MENU_DURATION + 60}ms ease-in-out 5s;
    touch-action: ${(p) => (p.isMobileMenuActive ? 'none' : 'initial')};
    width: 100vw;
  `}
`
// ${p => (p.isMobileMenuActive || p.mask ? 'fixed' : 'relative')};

export function calculateMobileContainerStyles(position: number): {} {
  const { width, height } = getWindowDimensions()
  const breakpoint = getBreakpointPxFromTheme('tablet_large')

  const styles = {
    opacity: position > height ? 0 : 1,
    transform: `translateY(-${position * 0.11}px)`
  }

  return width > breakpoint || position <= 0 ? {} : styles
}

const MaskMobile = styled.div<{ shouldMask: boolean }>`
  opacity: ${(p) => (p.shouldMask ? 1 : 0)};
  transition: opacity 0.5s linear;
  pointer-events: none;
  background: ${(p) => p.theme.colors.background};

  ${mediaqueries.tablet`
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9; 
  `}
`